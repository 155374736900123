<template>
  <div class="events-detail content">
    <div class="ach-from-template--actions-header">
      <ClSearch
        placeholder="tableHeader.search"
        @searchUpdate="searchData"
      />
    </div>
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody v-if="ready">
            <CDataTable
              ref="eventTable"
              id="eventTable"
              :items="awardsData"
              :fields="tableFields"
              class="zq--table"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              sorter
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #id="{ item }">
                <td :class="{'column-shadow-start': isTableScrollbar}">
                  <div class="copy-ceil">
                    <router-link :to="{ name: `PreviewAward`, params: {id: item.id} }">
                      {{ item.id }}
                    </router-link>
                    <i
                      class="fa fa-copy zq-card-row--copy-icon"
                      @click="handleCopy(item.id)"
                    />
                  </div>
                </td>
              </template>
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #actions="{item}">
                <td :data-id="item.id" class="fixed-column-end">
                  <div class="zq--table-actions-wrap">
                    <div class="competitions-icon-actions zq--actions-table" @click="showDetails(item)">
                      <img src="../../../assets/icons/search.svg" alt="search">
                    </div>
                  </div>
                </td>
              </template>
              <!--       Table Filters         -->
              <template #actions-header>
                <div v-theme-header-actions></div>
              </template>
              <!--     Actions  Filter      -->
              <template #actions-filter>
                <div></div>
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="totalRecords"
              :itemsPerPage="itemsPerPage"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ClSearch from '@/shared/components/formComponents/ClSearch';
import TableLoader from '@/components/table/Loader';
import TableFooter from '@/components/table/Footer';
import { mapActions, mapGetters } from 'vuex';
import { checkNullItem } from '@/helpers/checkNullItem';
import { pageConfig } from '@/config';
import { dateFormate } from '@/utils/dateFormate';
import { cloneDeep } from 'lodash';

export default {
  name: "AwardsDetails",
  components: {
    ClSearch,
    TableLoader,
    // ColumnFilter,
    TableFooter,
  },
  props: {
    entityType: {
      type: String,
      default: 'Achievement'
    }
  },
  data() {
    return {
      ready: false,
      columnFilter: false,
      isTableScrollbar: false,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      awardsData: [],
      tableFields: [],
      query: [
          {
            queryField: 'entityType',
            queryValues: [this.entityType],
          },
        {
          queryField: 'entityId',
          queryValues: [this.entityType === 'Contest' ? this.$route.params.contestId : this.$route.params.id],
        }
      ],
    }
  },
  computed: {
    ...mapGetters('awards', [
      'awards',
      'fields',
      'pages',
      'loading',
      'resultCount',
      'totalRecords',
      'originalFields',
    ]),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        }
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('awards', ['handleFields', 'handleGetAwardsByQuery']),
    initialize() {
      this.tableFields = this.fields.filter(item => item !== 'select');
      this.handleGetAwardsByQuery(
        {
          queryRequest: {
            must: this.query,
            sortBy: this.sortBy,
            skip: (this.page - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
          }
        }
      ).then((data) => {
        this.awardsData = cloneDeep(data).sort(this.rewardRankSort);

        this.ready = true;
      });
      this.handleFields();
    },
    rewardRankSort(a, b) {
      const rankA = this.parseRewardRank(a.rewardRank);
      const rankB = this.parseRewardRank(b.rewardRank);

      if (rankA < rankB) {
        return -1;
      }
      if (rankA > rankB) {
        return 1;
      }
      return 0;
    },
    parseRewardRank(rank) {
      const parts = rank.split('-');
      return parseInt(parts[0]);
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    showDetails(item) {
      this.$router.push({
        name: 'PreviewEvent',
        params: {
          id: item.id
        }
      })
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetAwardsByQuery(
        {
          queryRequest: {
            must: this.query,
            sortBy: this.sortBy,
            skip: (this.page - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
          }
        }
      );
    },
    paginationChange(val) {
      this.page = val;
      this.handleGetAwardsByQuery(
        {
          queryRequest: {
            must: this.query,
            sortBy: this.sortBy,
            skip: (this.page - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
          }
        }
      ).then(data => this.awardsData = data);
    },
    async searchData(value) {
      let multiFields = [];
      if (value.trim()) {
        multiFields.push({
          queryFields: this.searchableAllFields,
          queryValue: value
        })
      }
      await this.handleGetAwardsByQuery({queryRequest:
          {
            multiFields: multiFields,
            sortBy: this.sortBy,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
      });
    },
    handleCopy(id) {
      navigator.clipboard.writeText(id);
    },
  },
  watch: {
    events(val) {
      this.awardsData = checkNullItem(val);
    }
  },
}
</script>

<style scoped>
.copy-ceil {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zq-card-row--copy-icon {
  margin-left: 0.5rem;
  cursor: copy;
}
</style>